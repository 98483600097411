import React from 'react';

import { FormattedMessage } from '../../util/reactIntl';
import { isFieldForListingType } from '../../util/fieldHelpers';

import { Heading } from '../../components';

import { filterparamsTranslations, getTranslation } from '../../translations/TranslationsProvider';

import css from './ListingPage.module.css';

const SectionDetailsMaybe = props => {
  const { publicData, metadata = {}, listingFieldConfigs, isFieldForCategory, monthlyTimeSlots, intl } = props;

  if (!publicData || !listingFieldConfigs) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType = isFieldForListingType(listingType, config);
    const isTargetCategory = isFieldForCategory(config);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && isTargetListingType && isTargetCategory && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
          ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
          : schemaType === 'long'
            ? filteredConfigs.concat({ key, value, label })
            : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFieldConfigs.reduce(pickListingFields, []);

  const processTimeSlots = (timeSlotsObj) => {
    // Helper function to create a uniform array of availability timeslots
    // array of this: [startDate, endDate, seats]
    const allFetchesDone = Object.values(timeSlotsObj).every(
      month => !month.fetchTimeSlotsInProgress
    );

    if (!allFetchesDone) {
      return [];
    }

    // Step 2: Reduce the object to an array of arrays
    const timeSlotsArray = Object.values(timeSlotsObj).reduce((acc, month) => {
      if (month.timeSlots) {
        const slots = month.timeSlots.map(slot => [
          slot.attributes.start,
          slot.attributes.end,
          slot.attributes.seats
        ]);
        return acc.concat(slots);
      }
      return acc;
    }, []);

    return timeSlotsArray;
  };

  const processedTimeSlots = processTimeSlots(monthlyTimeSlots);

  const weekDayFormatted = dayOfWeek => {

    const ids = [
      "EditListingAvailabilityPlanForm.dayOfWeek.sun",
      "EditListingAvailabilityPlanForm.dayOfWeek.mon",
      "EditListingAvailabilityPlanForm.dayOfWeek.tue",
      "EditListingAvailabilityPlanForm.dayOfWeek.wed",
      "EditListingAvailabilityPlanForm.dayOfWeek.thu",
      "EditListingAvailabilityPlanForm.dayOfWeek.fri",
      "EditListingAvailabilityPlanForm.dayOfWeek.sat",
    ];
    return (intl.formatMessage({ id: ids[dayOfWeek] }));
  };

  const formatAvailabilityMessage = (processedTimeSlots, i, idx = 0) => {
    // Helper function to format the 'first available' timeslot messages
    const timeSlot = processedTimeSlots[idx];
    const weekDay = weekDayFormatted(timeSlot[i].getDay());
    const dateString = timeSlot[i].toLocaleDateString('sv');
    const timeString = timeSlot[i].toLocaleTimeString('sv').substr(0, 5);
    const seatCount = timeSlot[2];
    const seatsMessage = i === 0 ? intl.formatMessage({id: 'EditListingAvailabilityPanel.WeeklyCalendar.availableSpots'},{ seats: seatCount }) : '';

    let message = `${weekDay}, ${dateString}, ${timeString} ${seatsMessage}`
    return message;
  }

  return existingListingFields.length > 0 ? (
    <>
      <section className={css.sectionDetails}>
        <Heading as="h2" rootClassName={css.sectionHeading}>
          <FormattedMessage id="ListingPage.detailsTitle" />
        </Heading>
        <ul className={css.details}>
          {existingListingFields.map(detail => {
            // Translation for listing details (same or similar to the search filter parameter names)
            detail.label = getTranslation(filterparamsTranslations, detail.label);
            detail.value = getTranslation(filterparamsTranslations, detail.value);
            return (
              <li key={detail.key} className={css.detailsRow}>
                <span className={css.detailLabel}>{detail.label}</span>
                <span>{detail.value}</span>
              </li>
            )
          })
          }
        </ul>
      </section>

      {processedTimeSlots.length > 0 ?
        <section className={css.sectionDetails}>
          <Heading as="h2" rootClassName={css.sectionHeading}>
            <FormattedMessage id="ListingPage.availabilityNext" />
          </Heading>
          <ul className={css.details}>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.availabilityStartsAt" />
              </span>
            </li>
            <li className={css.detailsRow}>
              <span>{formatAvailabilityMessage(processedTimeSlots, 0)}</span>
            </li>
            <li className={css.detailsRow}>
              <span className={css.detailLabel}>
                <FormattedMessage id="ListingPage.availabilityEndsAt" />
              </span>
            </li>
            <li className={css.detailsRow}>
            <span>{formatAvailabilityMessage(processedTimeSlots, 1)}</span>
            </li>
          </ul>
        </section>
        : null
      }
    </>
  ) : null;
};

export default SectionDetailsMaybe;
